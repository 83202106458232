import { graphql } from 'gatsby';
import React, { useState } from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { FinishInfo, Slab, Material, Tile } from '../components/shared/interfaces';
import { Modal } from '../components/shared/modal/Modal';
import ProductContainer from '../components/shared/product-container/ProductContainer';
import CollapseFiltersSideBar from '../components/slab/collapse-filters-side-bar/CollapseFiltersSideBar';
import { FilterType } from '../components/slab/collapse-filters-side-bar/filter-type.enum';
import MobileFiltersBtn from '../components/slab/mobile-filters/mobile-filters-btn/MobileFiltersBtn';
import MobileFilters from '../components/slab/mobile-filters/MobileFilters';
import {
  createSortFn,
  filterProducts,
  loadFilterState,
  saveFilterState,
} from '../components/slab/sort-filter/filter-utils';
import SortFilter from '../components/slab/sort-filter/SortFilter';
import { TileState } from '../components/tile/tile-state.interface';
import useFavourite from '../hooks/useFavourite';
import useViewport from '../hooks/useViewport';
import styles from './search.module.scss';
import { SlugType } from '../templates/slug-type.interface';
import { SSL_OP_TLS_ROLLBACK_BUG } from 'constants';

const TITLE = 'SEARCH';

export const query = graphql`
  query {
    strapi {
      materials {
        id
        name
        sort_index
      }
      slabs {
        created_at
        id
        sku
        isSaleSlab: SaleSlabs
        color_primary {
          id
          name
        }
        color_secondary {
          id
          name
        }
        color_tertiary {
          id
          name
        }
        material {
          id
          name
        }
        finish {
          id
          name
        }
        images {
          url
        }
        thickness
        description
        name
        size
        preview {
          url
        }
      }
      tiles {
        leadTime: LeadTime
        range: Range
        isInStock: inStock
        isDealer: dealer
        externalLink: externalUrl
        id
        created_at
        name
        tile_collection {
          name
          tile_collection_page {
            slug
          }
        }
        description
        material {
          name
          id
        }
        decorative_materials {
          name
          id
        }
        color {
          id
          name
        }
        color_secondary {
          id
          name
        }
        color_tertiary {
          id
          name
        }
        images {
          url
          caption
        }
        preview {
          url
        }
        finishes: tile_finishes {
          finish {
            id
          }
          sizes
          image {
            url
          }
        }
      }
      finishes {
        name
        id
        is_textured
      }
    }
  }
`;

interface Props {
  data: {
    strapi: {
      slabs: Slab[];
      finishes: FinishInfo[];
      materials: Material[];
      tiles: Tile[];
    };
  };
  location: Location;
}

interface SearchState extends TileState {
  initDecorativeTile: Tile[];
  decorativeTile: Tile[];
  initFieldTile: Tile[];
  fieldTile: Tile[];
  initOutdoorTile: Tile[];
  outdoorTile: Tile[];
  allProducts: Array<Slab | Tile>;
}

const SearchPage: React.FC<Props> = (props: Props) => {
  const materials = props.data.strapi.materials;
  const searchStr = new URLSearchParams(props.location.search).get('q');

  const pageName = 'search:' + searchStr;

  const initialState = getInitialState(props, searchStr, pageName);

  const { isTablet } = useViewport();
  const favouriteAPI = useFavourite();

  const [state, setState] = useState<SearchState>(initialState);
  const updateState = (newState: SearchState) => {
    saveFilterState(pageName, newState.filtersState, newState.sortState);
    setState(newState);
  };

  if (typeof window === 'undefined') {
    return <div></div>;
  }

  const switchFilterPopup = (): void =>
    updateState({ ...state, isFilterPopupOpen: !state.isFilterPopupOpen });

  const clearFilter = (
    filter?: FilterType.MATERIAL | FilterType.FINISH | FilterType.COLOR | FilterType.DIMENSIONS,
  ) => {
    if (filter) {
      state.filtersState[filter] = [];
    } else {
      state.filtersState = {
        [FilterType.IS_IN_STOCK]: false,
        [FilterType.IS_DEALER]: false,
        [FilterType.SEARCH]: '',
        [FilterType.COLOR]: [],
        [FilterType.MATERIAL]: [],
        [FilterType.FINISH]: [],
        [FilterType.DIMENSIONS]: [],
      };
    }

    applyFilters();
  };

  function applyFilters(): void {
    updateState(getFilteredState(state));
  }

  const updateFilters = (filterType: FilterType, param: string) => {
    const filtersState = state.filtersState;

    if (
      filterType === FilterType.COLOR ||
      filterType === FilterType.MATERIAL ||
      filterType === FilterType.FINISH
    ) {
      const values = filtersState[filterType];
      if (values.includes(param)) {
        filtersState[filterType] = values.filter((v) => v !== param);
      } else {
        values.push(param);
      }
    }

    if (filterType === FilterType.SEARCH) {
      filtersState[filterType] = param;
    }

    applyFilters();
  };

  return (
    <Layout
      isWhiteBackground={true}
      bottomFloatingComponent={
        isTablet && <MobileFiltersBtn name={'FILTER'} onClick={switchFilterPopup} />
      }
    >
      <SEO title={TITLE} />
      <section className={styles.wrapper}>
        <h2>Search results for: {searchStr}</h2>
        <div className={styles.topFilter}>
          <SortFilter
            sortState={state.sortState}
            updateSortState={(update) => {
              (state.sortState = update) && applyFilters();
            }}
          />
        </div>
        <section className={styles.content}>
          <div className={styles.filters}>
            <CollapseFiltersSideBar
              materials={materials}
              tiles={state.allProducts}
              isRenderFinish={true}
              filtersState={state.filtersState}
              switchSelection={updateFilters}
              isSaleSlabs={true}
              isNestedSearch={true}
            />
          </div>

          <div className={styles.productsContent}>
            {!!state.loadedTiles.length && <h3>SLAB</h3>}
            {!!state.loadedTiles.length && (
              <div className={styles.slabCardsContainer}>
                {state.loadedTiles.map((slab) =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  // prettier-ignore
                  <ProductContainer {...slab} favouriteAPI={favouriteAPI} preview={{ url: slab.preview.url }} key={'slab'+slab.id} path={`/slab/${slab.id}`} slab/>,
                )}
              </div>
            )}

            {!!state.fieldTile.length && <h3>FIELD TILE</h3>}
            {!!state.fieldTile.length && (
              <div className={styles.slabCardsContainer}>
                {state.fieldTile.map((t) => (
                  <ProductContainer
                    favouriteAPI={favouriteAPI}
                    isDecorative={false}
                    {...t}
                    key={`tile_${SlugType.FIELD}_${t.id}`}
                    path={`/tile/${SlugType.FIELD}/${t.id}`}
                  />
                ))}
              </div>
            )}

            {!!state.decorativeTile.length && <h3>DECORATIVE TILE</h3>}
            {!!state.decorativeTile.length && (
              <div className={styles.slabCardsContainer}>
                {state.decorativeTile.map((t) => (
                  <ProductContainer
                    favouriteAPI={favouriteAPI}
                    isDecorative={true}
                    {...t}
                    key={`tile_${SlugType.DECORATIVE}_${t.id}`}
                    path={`/tile/${SlugType.DECORATIVE}/${t.id}`}
                  />
                ))}
              </div>
            )}

            {!!state.outdoorTile.length && <h3>OUTDOOR TILE</h3>}
            {!!state.outdoorTile.length && (
              <div className={styles.slabCardsContainer}>
                {state.outdoorTile.map((t) => (
                  <ProductContainer
                    favouriteAPI={favouriteAPI}
                    isDecorative={false}
                    {...t}
                    key={`tile_${SlugType.OUTDOOR}_${t.id}`}
                    path={`/tile/${SlugType.OUTDOOR}/${t.id}`}
                  />
                ))}
              </div>
            )}
          </div>
        </section>
      </section>

      <Modal isOpen={state.isFilterPopupOpen} handleClose={switchFilterPopup}>
        <MobileFilters
          materials={materials}
          tiles={state.allProducts}
          isRenderFinish={true}
          filtersState={state.filtersState}
          isSlab={true}
          switchSelection={updateFilters}
          onClearAll={clearFilter}
          onClose={switchFilterPopup}
        />
      </Modal>
    </Layout>
  );
};

function getFilteredState(state: SearchState): SearchState {
  const {
    tiles,
    initDecorativeTile,
    initFieldTile,
    initOutdoorTile,
    sortState,
    filtersState,
  } = state;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const slab = filterProducts(tiles, filtersState, sortState);

  const decorativeTile = filterProducts(initDecorativeTile, filtersState, sortState, true);
  const fieldTile = filterProducts(initFieldTile, filtersState, sortState);
  const outdoorTile = filterProducts(initOutdoorTile, filtersState, sortState);

  return { ...state, loadedTiles: slab, decorativeTile, fieldTile, outdoorTile };
}

function getInitialState(
  {
    data: {
      strapi: { finishes, slabs, tiles },
    },
  }: Props,
  searchStr: string | null,
  pageName: string,
): SearchState {
  const filtersStoredState = loadFilterState(pageName);

  const initialState: SearchState = {
    isFilterPopupOpen: false,
    filtersState: filtersStoredState.filters,
    tiles: [],
    loadedTiles: [],

    initDecorativeTile: [],
    decorativeTile: [],
    initFieldTile: [],
    fieldTile: [],
    initOutdoorTile: [],
    outdoorTile: [],
    allProducts: [],

    sortState: filtersStoredState.sortState,
  };

  slabs = slabs.filter((s) => {
    if (searchStr) {
      return (
        s.sku?.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()) ||
        s.name.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase())
      );
    } else {
      return true;
    }
  });

  for (const slabInfo of slabs) {
    const finishId = slabInfo.finish?.id;

    if (finishId) {
      const finish = finishes.find((f) => f.id === finishId);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      slabInfo.finish.is_textured = finish.is_textured;
      slabInfo.finishes = [slabInfo.finish];
    } else {
      slabInfo.finishes = [];
    }

    slabInfo.colors = [
      slabInfo.color_primary,
      slabInfo.color_secondary,
      slabInfo.color_tertiary,
    ].filter((c) => c);
  }

  tiles = tiles.filter(
    (t) =>
      t.tile_collection &&
      (searchStr ? t.name.toLocaleLowerCase().includes(searchStr.toLocaleLowerCase()) : true),
  );
  for (const tileInfo of tiles) {
    for (const f of tileInfo.finishes) {
      if (f.finish) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const finish = finishes.find((inf) => inf.id === f.finish?.id)!;
        f.name = finish.name;
        f.is_textured = finish.is_textured;
      }
    }
  }

  initialState.allProducts = [...slabs, ...tiles];

  const sortState = initialState.sortState;
  const sortFunc = createSortFn(sortState);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // prettier-ignore
  initialState.initDecorativeTile = tiles.filter((t) => t.tile_collection.tile_collection_page.slug === SlugType.DECORATIVE).sort(sortFunc);
  initialState.decorativeTile = initialState.initDecorativeTile;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // prettier-ignore
  initialState.initFieldTile = tiles.filter((t) => t.tile_collection.tile_collection_page.slug === SlugType.FIELD).sort(sortFunc);
  initialState.fieldTile = initialState.initFieldTile;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // prettier-ignore
  initialState.initOutdoorTile = tiles.filter((t) => t.tile_collection.tile_collection_page.slug === SlugType.OUTDOOR).sort(sortFunc);
  initialState.outdoorTile = initialState.initOutdoorTile;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // prettier-ignore
  initialState.tiles = slabs.sort(sortFunc);
  initialState.loadedTiles = initialState.tiles;

  return getFilteredState(initialState);
}

export default SearchPage;
